import React from 'react';
import { Button } from '@/components/ui/button';
import { Card, CardContent } from '@/components/ui/card';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { useUpdateFlashcard } from '@/query';
import { FlashcardResponse } from 'flashcards-api-schema';
import { CircularProgress } from './circular-progress';
import { useCreateReview } from '@/query/review';
import { Pin, PinOff } from 'lucide-react';
import { useToast } from '@/hooks/use-toast';

interface FlashcardProps {
  currentCard: FlashcardResponse;
  isFlipped: boolean;
  handleFlipCard: () => void;
}

interface CardHeaderProps {
  currentCard: FlashcardResponse;
  isFlipped: boolean;
}

const CardHeader: React.FC<CardHeaderProps> = ({ currentCard, isFlipped }) => {
  const updateFlashcard = useUpdateFlashcard();
  const { toast } = useToast();

  const handlePriorityChange = (value: string): void => {
    updateFlashcard.mutate({
      id: currentCard.id,
      data: { priority: parseInt(value) },
    });
    toast({
      title: 'Priority updated',
      description: `Card priority set to P${value}`,
      variant: 'default',
    });
  };

  return (
    <>
      <h2 className="absolute left-2 top-2 text-sm text-gray-500">
        {isFlipped ? 'Back' : 'Front'}
      </h2>

      <div
        className="absolute right-12 top-2"
        onClick={(e: React.MouseEvent) => e.stopPropagation()}
      >
        <Select
          value={currentCard.priority?.toString() ?? '3'}
          onValueChange={handlePriorityChange}
        >
          <SelectTrigger className="w-[80px]">
            <SelectValue placeholder="Priority" />
          </SelectTrigger>

          <SelectContent>
            <SelectItem value="0">P0</SelectItem>

            <SelectItem value="1">P1</SelectItem>

            <SelectItem value="2">P2</SelectItem>

            <SelectItem value="3">P3</SelectItem>
          </SelectContent>
        </Select>
      </div>

      <Button
        className="absolute right-2 top-2"
        size="icon"
        variant="outline"
        onClick={(e: React.MouseEvent) => {
          e.stopPropagation();
          updateFlashcard.mutate({
            id: currentCard.id,
            data: { isPinned: !currentCard.isPinned },
          });
          toast({
            title: currentCard.isPinned ? 'Card unpinned' : 'Card pinned',
            description: currentCard.isPinned
              ? 'Card removed from pinned cards'
              : 'Card added to pinned cards',
            variant: 'default',
          });
        }}
      >
        {currentCard.isPinned ? <PinOff size={16} /> : <Pin size={16} />}
      </Button>
    </>
  );
};

interface ReviewButtonsProps {
  currentCard: FlashcardResponse;
}

const ReviewButtons: React.FC<ReviewButtonsProps> = ({ currentCard }) => {
  const createReview = useCreateReview();

  return (
    <div className="flex w-full justify-around gap-4 px-6">
      <Button
        size="sm"
        className="border border-red-500 bg-transparent text-red-500 hover:bg-red-500 hover:text-white"
        onClick={(e: React.MouseEvent) => {
          e.stopPropagation();
          createReview.mutate({
            flashcardId: currentCard.id,
            reviewScore: 0,
          });
        }}
      >
        Again
      </Button>

      <Button
        size="sm"
        className="border border-orange-500 bg-transparent text-orange-500 hover:bg-orange-500 hover:text-white"
        onClick={(e: React.MouseEvent) => {
          e.stopPropagation();
          createReview.mutate({
            flashcardId: currentCard.id,
            reviewScore: 3,
          });
        }}
      >
        Hard
      </Button>

      <Button
        size="sm"
        className="border border-yellow-500 bg-transparent text-yellow-500 hover:bg-yellow-500 hover:text-white"
        onClick={(e: React.MouseEvent) => {
          e.stopPropagation();
          createReview.mutate({
            flashcardId: currentCard.id,
            reviewScore: 7,
          });
        }}
      >
        Good
      </Button>

      <Button
        size="sm"
        className="border border-green-500 bg-transparent text-green-500 hover:bg-green-500 hover:text-white"
        onClick={(e: React.MouseEvent) => {
          e.stopPropagation();
          createReview.mutate({
            flashcardId: currentCard.id,
            reviewScore: 10,
          });
        }}
      >
        Easy
      </Button>
    </div>
  );
};

export const FlashcardView: React.FC<FlashcardProps> = ({
  currentCard,
  isFlipped,
  handleFlipCard,
}) => {
  return (
    <Card
      className="relative flex aspect-video w-full max-w-md cursor-pointer flex-col items-center justify-between bg-slate-50 p-4 dark:bg-slate-900"
      onClick={handleFlipCard}
    >
      <CardHeader currentCard={currentCard} isFlipped={isFlipped} />

      <CardContent className="p-6 text-center">
        <p className="text-lg md:text-xl">{isFlipped ? currentCard.back : currentCard.front}</p>
      </CardContent>

      <ReviewButtons currentCard={currentCard} />

      <div className="flex w-full flex-row justify-end gap-6">
        <CircularProgress size="md" value={currentCard.masteryScore ?? 0} variant="success" />
      </div>
    </Card>
  );
};

import React from 'react';
import { Input } from '@/components/ui/input';

interface SearchInputProps {
  searchQuery: string;
  setSearchQuery: (value: string) => void;
}

export const SearchInput: React.FC<SearchInputProps> = ({ searchQuery, setSearchQuery }) => (
  <div className="flex items-center gap-2">
    <label htmlFor="search">Search:</label>

    <Input
      id="search"
      type="text"
      placeholder="Search questions or answers..."
      value={searchQuery}
      onChange={(e) => setSearchQuery(e.target.value)}
      className="w-full md:w-64"
    />
  </div>
);

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.flashcardResponseSchema = exports.flashcardRequestSchema = void 0;
const zod_1 = require("zod");
exports.flashcardRequestSchema = zod_1.z.object({
    front: zod_1.z.string().min(1, { message: "Front cannot be empty" }),
    back: zod_1.z.string().min(1, { message: "Back cannot be empty" }),
    isPinned: zod_1.z.boolean().optional(),
    isFlagged: zod_1.z.boolean().optional(),
    deckId: zod_1.z.string().min(1, { message: "Deck ID cannot be empty" }),
    priority: zod_1.z.number().min(0).max(3).optional(),
});
exports.flashcardResponseSchema = zod_1.z.object({
    id: zod_1.z.string(),
    front: zod_1.z.string(),
    back: zod_1.z.string(),
    createdAt: zod_1.z.date(),
    updatedAt: zod_1.z.date().nullable(),
    isPinned: zod_1.z.boolean(),
    isFlagged: zod_1.z.boolean(),
    reviewCount: zod_1.z.number(),
    correctCount: zod_1.z.number(),
    lastReviewedAt: zod_1.z.date().nullable(),
    deckId: zod_1.z.string().min(1, { message: "Deck ID cannot be empty" }),
    masteryScore: zod_1.z.number().optional(),
    priority: zod_1.z.number().min(0).max(3).default(3),
});
// TODO: Add nextReviewAt

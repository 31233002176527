import { useSelectedDecks } from './use-selected-decks';
import { FlashcardResponse, DeckResponse } from 'flashcards-api-schema';
import { useFetchFlashcards } from '../query';
import { useMemo } from 'react';

const sortByMastery = (a: FlashcardResponse, b: FlashcardResponse) =>
  (a.masteryScore ?? 0) - (b.masteryScore ?? 0);

const filterByCustomDeck = (flashcard: FlashcardResponse, deckId: string) =>
  flashcard.deckId === deckId;

const filterByRegularDecks = (flashcard: FlashcardResponse, decks: DeckResponse[] | undefined) =>
  decks?.some((deck) => deck.id === flashcard.deckId) ?? false;

export const useCurrentFlashcards = () => {
  const {
    selectedRegularDecks,
    selectedCustomDeck,
    isLoading: isDecksLoading,
    error: decksError,
  } = useSelectedDecks();
  const {
    data: flashcards,
    isLoading: isFlashcardsLoading,
    error: flashcardsError,
  } = useFetchFlashcards();

  const currentFlashcards = useMemo(() => {
    if (!flashcards) return [];

    const filteredCards = selectedCustomDeck
      ? flashcards.filter((card) => filterByCustomDeck(card, selectedCustomDeck.id))
      : flashcards.filter((card) => filterByRegularDecks(card, selectedRegularDecks));

    return filteredCards.sort(sortByMastery);
  }, [selectedCustomDeck, selectedRegularDecks, flashcards]);

  return {
    currentFlashcards,
    isLoading: isDecksLoading || isFlashcardsLoading,
    error: decksError || flashcardsError,
  };
};

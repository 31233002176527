import { useFetchDecks } from '@/query';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import MaterialDeckTree from './DeckTree';
import NewDeckDialog from './NewDeckDialog';
import { useStore } from '@/hooks/use-store';

export default function DecksSidebar() {
  const { data: decks, isLoading: isLoadingDecks, error: errorDecks } = useFetchDecks();
  const { selectedRegularDeckIds, updateSelectedRegularDeckIds } = useStore();

  const handleSelectedDeckIdsChange = (_event: React.SyntheticEvent, ids: string[]) => {
    updateSelectedRegularDeckIds(ids);
  };

  if (isLoadingDecks) {
    return <div>Loading...</div>;
  }
  if (errorDecks) {
    return <div>Error: {errorDecks.message}</div>;
  }

  return (
    <div className="flex w-72 flex-col gap-4 p-4">
      <div className="flex justify-between">
        <h2 className="mb-4 text-lg font-bold">Decks</h2>

        <NewDeckDialog />
      </div>

      <SimpleTreeView
        selectedItems={selectedRegularDeckIds}
        onSelectedItemsChange={handleSelectedDeckIdsChange}
        multiSelect
      >
        {decks
          ?.filter((deck) => !deck.parentDeckId)
          .map((deck) => <MaterialDeckTree key={deck.id} deck={deck} />)}
      </SimpleTreeView>

      <div className="flex flex-col gap-0">
        <h2 className="mb-4 text-lg font-bold">Custom Decks</h2>

        <p className="text-sm text-gray-500">
          Create custom decks by selecting decks from the list above and clicking the &quot;Create
          Custom Deck&quot; button.
        </p>
      </div>
    </div>
  );
}

'use client';

import { useState } from 'react';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet';
import { ScrollArea } from '@/components/ui/scroll-area';
import { PinIcon } from 'lucide-react';
import { useStore } from '@/hooks/use-store';
import { usePinned } from '@/hooks/use-pinned';
import { FlashcardResponse } from 'flashcards-api-schema';

interface PinnedCardProps {
  card: FlashcardResponse;
  clearCard: (id: string) => void;
}

const PinnedCard: React.FC<PinnedCardProps> = ({ card, clearCard }) => {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleFlipCard = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <Card key={card.id} className="mb-4" onClick={handleFlipCard}>
      <CardHeader className="flex-row items-center justify-between p-2">
        <p>{!isFlipped ? 'front' : 'back'}</p>

        <Button
          variant="outline"
          size="sm"
          onClick={(e) => {
            e.stopPropagation();
            clearCard(card.id);
          }}
        >
          Unpin
        </Button>
      </CardHeader>

      <CardContent>
        <CardTitle>{isFlipped ? card.back : card.front}</CardTitle>
      </CardContent>
    </Card>
  );
};

export default function PinnedCardsSheet() {
  const { isPinnedCardsOpen, updateIsPinnedCardsOpen } = useStore();
  const { pinned, isLoading, error, clearCard, clearAll } = usePinned();

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error loading pinned cards.</p>;
  }

  return (
    <>
      <Sheet open={isPinnedCardsOpen} onOpenChange={updateIsPinnedCardsOpen}>
        <SheetTrigger asChild>
          <Button variant="outline" size="icon" aria-label="Open pinned cards">
            <PinIcon />
          </Button>
        </SheetTrigger>

        <SheetContent className="flex w-[400px] flex-col sm:w-[540px]">
          <SheetHeader>
            <SheetTitle>Pinned Cards</SheetTitle>

            <SheetDescription>Your most important flashcards at a glance.</SheetDescription>
          </SheetHeader>

          <ScrollArea className="mt-6 flex-1 rounded-md border p-4">
            {pinned && pinned.length > 0 ? (
              pinned.map((card) => <PinnedCard key={card.id} card={card} clearCard={clearCard} />)
            ) : (
              <p>No pinned cards available.</p>
            )}
          </ScrollArea>

          {pinned && pinned.length > 0 && (
            <Button variant="outline" onClick={clearAll} className="mt-4">
              Clear All
            </Button>
          )}
        </SheetContent>
      </Sheet>
    </>
  );
}

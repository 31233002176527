import React from 'react';
import { TrendingDown } from 'lucide-react';
import { CartesianGrid, XAxis, Line, LineChart, YAxis } from 'recharts';
import { ChartCard } from './ChartCard';
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from '@/components/ui/chart';

const chartConfig = {
  unseenCards: {
    label: 'Unseen Cards',
    color: 'hsl(var(--chart-2))',
  },
} satisfies ChartConfig;

const chartData = [
  { day: 'Monday', unseenCards: 50 },
  { day: 'Tuesday', unseenCards: 40 },
  { day: 'Wednesday', unseenCards: 30 },
  { day: 'Thursday', unseenCards: 20 },
  { day: 'Friday', unseenCards: 10 },
  { day: 'Saturday', unseenCards: 5 },
  { day: 'Sunday', unseenCards: 0 },
];

export const MockChartBurn: React.FC<object> = () => {
  const footer = (
    <>
      <div className="flex gap-2 font-medium leading-none">
        Trending down by 100% this week <TrendingDown className="h-4 w-4" />
      </div>

      <div className="leading-none text-muted-foreground">
        Showing the reduction of unseen cards over the past week
      </div>
    </>
  );

  return (
    <ChartCard title="Unseen Cards Burndown" description="Weekly Progress" footerContent={footer}>
      <ChartContainer config={chartConfig}>
        <LineChart data={chartData}>
          <CartesianGrid />

          <XAxis dataKey="day" tickMargin={10} />

          <YAxis />

          <ChartTooltip content={<ChartTooltipContent hideLabel />} />

          <Line
            type="monotone"
            dataKey="unseenCards"
            stroke="var(--color-unseenCards)"
            strokeWidth={2}
            dot={{ r: 4 }}
          />
        </LineChart>
      </ChartContainer>
    </ChartCard>
  );
};

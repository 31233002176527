import { useCallback } from 'react';
import { FlashcardResponse } from 'flashcards-api-schema';

export function useCardFilters(deckFilter: string, searchQuery: string) {
  const filterByDeck = useCallback(
    (cards: FlashcardResponse[]) => {
      if (deckFilter === 'All') return cards;
      return cards.filter((card) => card.deckId === deckFilter);
    },
    [deckFilter]
  );

  const filterBySearch = useCallback(
    (cards: FlashcardResponse[]) => {
      if (searchQuery.trim() === '') return cards;
      const query = searchQuery.toLowerCase();
      return cards.filter(
        (card) =>
          card.front.toLowerCase().includes(query) || card.back.toLowerCase().includes(query)
      );
    },
    [searchQuery]
  );

  return { filterByDeck, filterBySearch };
}

import {
  CardTitle,
  CardDescription,
  CardFooter,
  Card,
  CardHeader,
  CardContent,
} from '@/components/ui/card';
import React, { ReactNode } from 'react';

interface ChartCardProps {
  title: string;
  description: string;
  children: ReactNode;
  footerContent: ReactNode;
}
export const ChartCard: React.FC<ChartCardProps> = ({
  title,
  description,
  children,
  footerContent,
}) => {
  return (
    <Card className="h-80 w-full max-w-md">
      <CardHeader>
        <CardTitle>{title}</CardTitle>

        <CardDescription>{description}</CardDescription>
      </CardHeader>

      <CardContent>{children}</CardContent>

      <CardFooter className="flex-col items-start gap-2 text-sm">{footerContent}</CardFooter>
    </Card>
  );
};

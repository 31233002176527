import React from 'react';
import {
  Select,
  SelectTrigger,
  SelectContent,
  SelectItem,
  SelectValue,
} from '@/components/ui/select';

interface SortingControlsProps {
  sortKey: 'deck' | 'question' | 'answer';
  setSortKey: (value: 'deck' | 'question' | 'answer') => void;
  sortOrder: 'asc' | 'desc';
  setSortOrder: (value: 'asc' | 'desc') => void;
}

export const SortingControls: React.FC<SortingControlsProps> = ({
  sortKey,
  setSortKey,
  sortOrder,
  setSortOrder,
}) => (
  <div className="flex items-center gap-2">
    <label htmlFor="sort">Sort By:</label>

    <div className="flex gap-2">
      <Select value={sortKey} onValueChange={setSortKey}>
        <SelectTrigger className="w-40">
          <SelectValue placeholder="Select sort key" />
        </SelectTrigger>

        <SelectContent>
          <SelectItem value="deck">Deck</SelectItem>

          <SelectItem value="question">Question</SelectItem>

          <SelectItem value="answer">Answer</SelectItem>
        </SelectContent>
      </Select>

      <Select value={sortOrder} onValueChange={setSortOrder}>
        <SelectTrigger className="w-28">
          <SelectValue placeholder="Order" />
        </SelectTrigger>

        <SelectContent>
          <SelectItem value="asc">Ascending</SelectItem>

          <SelectItem value="desc">Descending</SelectItem>
        </SelectContent>
      </Select>
    </div>
  </div>
);

import { MockChart } from './MockChart';
import { MockChartBurn } from './MockChartBurn';
import { MockChartMastery } from './MockChartMastery';
import { MockChartNew } from './MockChartNew';
import React from 'react';

export const StatsView: React.FC<object> = () => {
  return (
    <div className="flex w-full flex-wrap items-start justify-center gap-32 overflow-y-auto">
      <MockChart />

      <MockChartBurn />

      <MockChartMastery />

      <MockChartNew />

      {/* Add more MockChart components as needed */}
    </div>
  );
};

import React from 'react';
import { cn } from '../../lib/utils';
import { CaretSortIcon } from '@radix-ui/react-icons';
import { Label } from '@radix-ui/react-label';
import { Check } from 'lucide-react';
import { Button } from '../ui/button';
import { Popover, PopoverContent, PopoverTrigger } from '../ui/popover';
import {
  CommandInput,
  CommandList,
  CommandEmpty,
  CommandGroup,
  CommandItem,
  Command,
} from '../ui/command';
import { DeckResponse } from 'flashcards-api-schema';

interface DeckSelectorProps {
  setDeckOpen: (deckOpen: boolean) => void;
  isDeckOpen: boolean;
  deckId: string | undefined;
  setDeckId: (deckId: string | undefined) => void;
  decks: DeckResponse[] | undefined;
}

const DeckCommandItem: React.FC<{
  deck: DeckResponse;
  deckId: string | undefined;
  onSelect: (value: string) => void;
}> = ({ deck, deckId, onSelect }) => {
  const checkClassName = cn('mr-2 h-4 w-4', deckId === deck.id ? 'opacity-100' : 'opacity-0');

  return (
    <CommandItem key={deck.id} value={deck.id} onSelect={onSelect}>
      {deck.name}

      <Check className={checkClassName} />
    </CommandItem>
  );
};

const DeckCommandContent: React.FC<{
  decks: DeckResponse[] | undefined;
  deckId: string | undefined;
  onSelect: (value: string) => void;
}> = ({ decks, deckId, onSelect }) => (
  <Command>
    <CommandInput placeholder="Search deck..." />

    <CommandList>
      <CommandEmpty>No deck found.</CommandEmpty>

      <CommandGroup>
        {decks?.map((deck) => (
          <DeckCommandItem key={deck.id} deck={deck} deckId={deckId} onSelect={onSelect} />
        ))}
      </CommandGroup>
    </CommandList>
  </Command>
);

const DeckPopoverContent: React.FC<{
  decks: DeckResponse[] | undefined;
  deckId: string | undefined;
  onSelect: (value: string) => void;
}> = ({ decks, deckId, onSelect }) => (
  <PopoverContent className="w-[200px] p-0">
    <DeckCommandContent decks={decks} deckId={deckId} onSelect={onSelect} />
  </PopoverContent>
);

const DeckSelectorLayout: React.FC<{
  deckOpen: boolean;
  selectedDeckName: string | undefined;
}> = ({ deckOpen, selectedDeckName }) => (
  <>
    <Label htmlFor="deck" className="text-right">
      Deck
    </Label>

    <Button
      variant="outline"
      role="combobox"
      aria-expanded={deckOpen}
      className="w-full justify-between"
    >
      {selectedDeckName || 'Select deck...'}

      <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
    </Button>
  </>
);

export const DeckSelector: React.FC<DeckSelectorProps> = ({
  deckId,
  isDeckOpen: deckOpen,
  decks,
  setDeckId,
  setDeckOpen,
}) => {
  const handleSelect = (currentValue: string) => {
    setDeckId(currentValue === deckId ? undefined : currentValue);
    setDeckOpen(false);
  };

  const selectedDeckName = deckId ? decks?.find((deck) => deck.id === deckId)?.name : undefined;

  return (
    <div className="grid grid-cols-4 items-center gap-4">
      <Popover open={deckOpen} onOpenChange={setDeckOpen} modal>
        <PopoverTrigger asChild>
          <div className="col-span-4 grid grid-cols-4 items-center gap-4">
            <DeckSelectorLayout deckOpen={deckOpen} selectedDeckName={selectedDeckName} />
          </div>
        </PopoverTrigger>

        <DeckPopoverContent decks={decks} deckId={deckId} onSelect={handleSelect} />
      </Popover>
    </div>
  );
};

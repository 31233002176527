import React, { useState, useMemo } from 'react';
import { useFetchDecks, useFetchFlashcards } from '@/query';
import { SortingControls } from './components/SortingControls';
import { DeckFilter } from './components/DeckFilter';
import { SearchInput } from './components/SearchInput';
import { FlashcardsTable } from './components/FlashcardsTable';
import { useCardFilters } from './hooks/useCardFilters';
import { useCardSorting, SortKey, SortOrder } from './hooks/useCardSorting';

const BrowseView: React.FC = () => {
  const { data: decks, isLoading: isDecksLoading, isError: isDecksError } = useFetchDecks();
  const { data: cards, isLoading: isCardsLoading, isError: isCardsError } = useFetchFlashcards();

  const [sortKey, setSortKey] = useState<SortKey>('deck');
  const [sortOrder, setSortOrder] = useState<SortOrder>('asc');
  const [deckFilter, setDeckFilter] = useState<string>('All');
  const [searchQuery, setSearchQuery] = useState<string>('');

  const { filterByDeck, filterBySearch } = useCardFilters(deckFilter, searchQuery);
  const { sortCards } = useCardSorting(decks);

  const sortedAndFilteredCards = useMemo(() => {
    if (!cards || !decks) return [];
    const filtered = filterBySearch(filterByDeck(cards));
    return sortCards(filtered, sortKey, sortOrder);
  }, [cards, decks, sortKey, sortOrder, filterByDeck, filterBySearch, sortCards]);

  if (isDecksLoading || isCardsLoading) return <div>Loading...</div>;
  if (isDecksError || isCardsError) return <div>Error fetching decks or cards</div>;
  if (!decks || !cards) return <div>No data available</div>;

  return (
    <div className="flex-1 overflow-y-auto p-4">
      <h2 className="mb-4 text-xl font-bold md:text-2xl">Browse All Cards</h2>

      <div className="mb-4 flex flex-col justify-between gap-4 md:flex-row">
        <SortingControls
          sortKey={sortKey}
          setSortKey={setSortKey}
          sortOrder={sortOrder}
          setSortOrder={setSortOrder}
        />

        <DeckFilter deckFilter={deckFilter} setDeckFilter={setDeckFilter} decks={decks} />

        <SearchInput searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
      </div>

      <FlashcardsTable cards={sortedAndFilteredCards} decks={decks} />
    </div>
  );
};

export default BrowseView;

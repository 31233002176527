import React from 'react';
import { Card } from '../../components/ui/card';
import { DeckActions } from './DeckActions';
import DecksSidebar from '@/components/DecksSidebar';
import { useStore } from '@/hooks/use-store';
import { StudyView } from './StudyView';
import { StatsView } from './StatsView';
import { useDeckHeaderName } from '@/hooks/use-deck-header-name';

export const DecksView: React.FC<object> = () => {
  const { selectedRegularDeckIds, decksTab } = useStore();
  const { name, loading, error } = useDeckHeaderName();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const noDeckSelected = selectedRegularDeckIds.length === 0;

  const noDeckSelectedContent = (
    <Card className="p-4">
      <h1 className="text-xl font-bold">No deck selected</h1>

      <p className="text-sm text-gray-500">Select a deck from the sidebar to view its cards.</p>
    </Card>
  );

  const renderCentralContent = () => {
    switch (decksTab) {
      case 'stats':
        return <StatsView />;
      case 'study':
        return <StudyView />;
      default:
        return noDeckSelected ? noDeckSelectedContent : null;
    }
  };

  return (
    <div className="flex h-full gap-16">
      <DecksSidebar />

      <div className="flex w-full flex-col gap-4">
        <div className="flex justify-between">
          <h1 className="text-2xl font-bold">{name}</h1>
        </div>

        <DeckActions />

        <div className="flex w-full grow items-start justify-center p-4">
          {renderCentralContent()}
        </div>
      </div>
    </div>
  );
};

import React from 'react';
import { TrendingUp } from 'lucide-react';
import { CartesianGrid, XAxis, Line, LineChart, YAxis } from 'recharts';
import { ChartCard } from './ChartCard';
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from '@/components/ui/chart';

const chartConfig = {
  mastery: {
    label: 'Average Mastery',
    color: 'hsl(var(--chart-3))',
  },
} satisfies ChartConfig;

const chartData = [
  { month: 'January', mastery: 5 },
  { month: 'February', mastery: 6 },
  { month: 'March', mastery: 7 },
  { month: 'April', mastery: 6.5 },
  { month: 'May', mastery: 8 },
  { month: 'June', mastery: 9 },
];

export const MockChartMastery: React.FC<object> = () => {
  const footer = (
    <>
      <div className="flex gap-2 font-medium leading-none">
        Trending up in mastery <TrendingUp className="h-4 w-4" />
      </div>

      <div className="leading-none text-muted-foreground">
        Showing average mastery level for the current deck over the last 6 months
      </div>
    </>
  );

  return (
    <ChartCard title="Average Mastery" description="January - June 2024" footerContent={footer}>
      <ChartContainer config={chartConfig}>
        <LineChart data={chartData}>
          <CartesianGrid />

          <XAxis dataKey="month" tickMargin={10} tickFormatter={(value) => value.slice(0, 3)} />

          <YAxis domain={[0, 10]} />

          <ChartTooltip content={<ChartTooltipContent hideLabel />} />

          <Line
            type="monotone"
            dataKey="mastery"
            stroke="var(--color-mastery)"
            strokeWidth={2}
            dot={{ r: 4 }}
          />
        </LineChart>
      </ChartContainer>
    </ChartCard>
  );
};

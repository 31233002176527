"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.customDeckResponseSchema = exports.customDeckRequestSchema = void 0;
const zod_1 = require("zod");
exports.customDeckRequestSchema = zod_1.z.object({
    name: zod_1.z.string().min(1, { message: "Name cannot be empty" }),
    deckIds: zod_1.z.array(zod_1.z.string()).min(1, { message: "Deck IDs cannot be empty" }),
});
exports.customDeckResponseSchema = zod_1.z.object({
    id: zod_1.z.string(),
    name: zod_1.z.string(),
    createdAt: zod_1.z.date(),
    updatedAt: zod_1.z.date(),
    deckIds: zod_1.z.array(zod_1.z.string()),
    type: zod_1.z.literal("custom").default("custom"),
});

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deckResponseSchema = exports.deckRequestSchema = void 0;
const zod_1 = require("zod");
exports.deckRequestSchema = zod_1.z.object({
    name: zod_1.z.string().min(1, { message: "Name cannot be empty" }), // Changed nonempty() to min(1)
    parentDeckId: zod_1.z.string().optional(),
    description: zod_1.z.string().optional(),
});
exports.deckResponseSchema = zod_1.z.object({
    id: zod_1.z.string(),
    name: zod_1.z.string(),
    description: zod_1.z.string().optional(),
    createdAt: zod_1.z.date(),
    updatedAt: zod_1.z.date(),
    flashcardIds: zod_1.z.array(zod_1.z.string()),
    subdeckIds: zod_1.z.array(zod_1.z.string()),
    parentDeckId: zod_1.z.string().optional(),
    type: zod_1.z.literal("regular").default("regular"),
});

import React from 'react';
import { TrendingUp } from 'lucide-react';
import { CartesianGrid, XAxis, Bar, BarChart } from 'recharts';
import { ChartCard } from './ChartCard';
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from '@/components/ui/chart';

const chartConfig = {
  newCards: {
    label: 'New Cards',
    color: 'hsl(var(--chart-1))',
  },
} satisfies ChartConfig;

const chartData = [
  { month: 'January', newCards: 30 },
  { month: 'February', newCards: 45 },
  { month: 'March', newCards: 60 },
  { month: 'April', newCards: 50 },
  { month: 'May', newCards: 70 },
  { month: 'June', newCards: 80 },
];

export const MockChartNew: React.FC<object> = () => {
  const footer = (
    <>
      <div className="flex gap-2 font-medium leading-none">
        Trending up in new cards added <TrendingUp className="h-4 w-4" />
      </div>

      <div className="leading-none text-muted-foreground">
        Showing the number of new cards added for the last 6 months
      </div>
    </>
  );

  return (
    <ChartCard title="New Cards Added" description="January - June 2024" footerContent={footer}>
      <ChartContainer config={chartConfig}>
        <BarChart accessibilityLayer data={chartData}>
          <CartesianGrid />

          <XAxis dataKey="month" tickMargin={10} tickFormatter={(value) => value.slice(0, 3)} />

          <ChartTooltip content={<ChartTooltipContent hideLabel />} />

          <Bar dataKey="newCards" fill="var(--color-newCards)" radius={8} />
        </BarChart>
      </ChartContainer>
    </ChartCard>
  );
};

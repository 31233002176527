import { useFetchDecks } from '@/query';
import { useStore } from './use-store';
import { DeckResponse } from 'flashcards-api-schema';
export const useSelectedDecks = () => {
  const {
    selectedRegularDeckIds,
    selectedCustomDeckId,
    updateSelectedRegularDeckIds,
    updateSelectedCustomDeckId,
  } = useStore();
  const { data: decks, isLoading, error } = useFetchDecks();
  const selectedRegularDecks = decks?.filter((deck) => selectedRegularDeckIds.includes(deck.id));
  const selectedCustomDeck = decks?.find((deck: DeckResponse) => deck.id === selectedCustomDeckId);
  return {
    selectedRegularDecks,
    selectedCustomDeck,
    updateSelectedRegularDeckIds,
    updateSelectedCustomDeckId,
    isLoading,
    error,
  };
};

import { useFetchDecks } from '@/query';
import { useEffect, useState } from 'react';
import { DeckSelector } from './DeckSelector';
import { DeckResponse } from 'flashcards-api-schema';
import { useStore } from '@/hooks/use-store';

interface UseDeckSelectorResult {
  deckId: string | undefined;
  DeckSelectorComponent: React.FC;
  setDeckId: (deckId: string | undefined) => void;
}

export const useDeckSelector = (): UseDeckSelectorResult => {
  const [deckId, setDeckId] = useState<string | undefined>(undefined);
  const [deckOpen, setDeckOpen] = useState(false);
  const { data: decks } = useFetchDecks();

  const { selectedRegularDeckIds } = useStore();

  useEffect(() => {
    if (selectedRegularDeckIds.length === 1) {
      setDeckId(selectedRegularDeckIds[0]);
    }
  }, [selectedRegularDeckIds]);

  const DeckSelectorComponent: React.FC = () => (
    <DeckSelector
      deckId={deckId}
      setDeckId={setDeckId}
      isDeckOpen={deckOpen}
      setDeckOpen={setDeckOpen}
      decks={decks as DeckResponse[]}
    />
  );

  return { deckId, setDeckId, DeckSelectorComponent };
};

import { FlashcardView } from '@/components/FlashcardView';
import { Button } from '@/components/ui/button';
import { useCurrentFlashcards } from '@/hooks/use-current-flashcards';
import { ArrowLeft, ArrowRight, Rotate3D } from 'lucide-react';
import React, { useState } from 'react';

export const StudyView: React.FC<object> = () => {
  const { currentFlashcards, isLoading, error } = useCurrentFlashcards();
  const [currentCardIndex, setCurrentCardIndex] = useState(0);

  // Only recompute the current card if the flashcard is no longer in the array
  const currentCard = currentFlashcards?.[currentCardIndex];
  const [isFlipped, setIsFlipped] = useState(false);
  const handleNextCard = () => {
    if (currentFlashcards && currentCardIndex < currentFlashcards.length - 1) {
      setCurrentCardIndex(currentCardIndex + 1);
      setIsFlipped(false);
      console.log('went to next card');
    }
  };

  const handlePreviousCard = () => {
    if (currentFlashcards && currentCardIndex > 0) {
      setCurrentCardIndex(currentCardIndex - 1);
      setIsFlipped(false);
      console.log('went to previous card');
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{`Error: ${error?.message}`}</div>;
  }

  if (!currentCard) {
    return <div>No cards in this deck</div>;
  }

  return (
    <div className="flex w-full flex-col items-center justify-center gap-16 p-16">
      <FlashcardView
        currentCard={currentCard}
        isFlipped={isFlipped}
        handleFlipCard={() => setIsFlipped(!isFlipped)}
      />

      <div className="flex justify-between gap-16">
        <Button onClick={handlePreviousCard} size="icon">
          <ArrowLeft />
        </Button>

        <Button
          onClick={() => setIsFlipped(!isFlipped)}
          className="flex items-center gap-2 rounded bg-blue-500 px-4 py-2 text-white"
        >
          <Rotate3D />
          Flip
        </Button>

        <Button onClick={handleNextCard} size="icon">
          <ArrowRight />
        </Button>
      </div>
    </div>
  );
};

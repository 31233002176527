import { useCallback } from 'react';
import { FlashcardResponse, DeckResponse } from 'flashcards-api-schema';

export type SortKey = 'deck' | 'question' | 'answer';
export type SortOrder = 'asc' | 'desc';

export function useCardSorting(decks: DeckResponse[] | undefined) {
  const getSortValue = useCallback(
    (card: FlashcardResponse, key: SortKey) => {
      switch (key) {
        case 'deck':
          return decks?.find((d) => d.id === card.deckId)?.name || card.deckId;
        case 'question':
          return card.front;
        case 'answer':
          return card.back;
        default:
          return '';
      }
    },
    [decks]
  );

  const sortCards = useCallback(
    (cards: FlashcardResponse[], sortKey: SortKey, sortOrder: SortOrder) => {
      return [...cards].sort((a, b) => {
        const aField = getSortValue(a, sortKey);
        const bField = getSortValue(b, sortKey);

        if (aField < bField) return sortOrder === 'asc' ? -1 : 1;
        if (aField > bField) return sortOrder === 'asc' ? 1 : -1;
        return 0;
      });
    },
    [getSortValue]
  );

  return { sortCards };
}

import * as React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';
import { cn } from '@/lib/utils';
import { Tooltip, TooltipContent, TooltipTrigger, TooltipProvider } from '@/components/ui/tooltip';

const circularProgressVariants = cva(
  'relative inline-flex items-center justify-center overflow-hidden rounded-full',
  {
    variants: {
      size: {
        sm: 'w-4 h-4',
        md: 'w-8 h-8',
        lg: 'w-12 h-12',
      },
      variant: {
        default: 'text-primary',
        secondary: 'text-secondary',
        destructive: 'text-destructive',
        success: 'text-green-500',
        warning: 'text-yellow-500',
      },
    },
    defaultVariants: {
      size: 'md',
      variant: 'default',
    },
  }
);

interface CircularProgressProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof circularProgressVariants> {
  value: number;
  showPercentage?: boolean;
}

interface CircleProps {
  className?: string;
  strokeWidth: number;
  radius: number;
  circumference?: number;
  percentage?: number;
}

const ProgressCircles: React.FC<CircleProps> = ({
  className,
  strokeWidth,
  radius,
  circumference,
  percentage,
}) => (
  <svg className="h-full w-full -rotate-90 transform">
    <circle
      className="opacity-20"
      strokeWidth={strokeWidth}
      stroke="currentColor"
      fill="transparent"
      r={radius}
      cx="50%"
      cy="50%"
    />

    <circle
      className={cn('transition-all duration-300 ease-in-out', className)}
      strokeWidth={strokeWidth}
      strokeDasharray={circumference}
      strokeDashoffset={circumference ? circumference - (percentage! / 100) * circumference : 0}
      strokeLinecap="round"
      stroke="currentColor"
      fill="transparent"
      r={radius}
      cx="50%"
      cy="50%"
    />
  </svg>
);

export const CircularProgress = React.forwardRef<HTMLDivElement, CircularProgressProps>(
  ({ className, size, variant, value, showPercentage = false, ...props }, ref) => {
    const percentage = Math.min(Math.max(value, 0), 100);
    const strokeWidth = 8;
    const radius = size === 'sm' ? 8 : size === 'lg' ? 24 : 16;
    const circumference = 2 * Math.PI * radius;

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
    };

    return (
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <div
              className={cn(circularProgressVariants({ size, variant }), className)}
              ref={ref}
              role="progressbar"
              aria-valuenow={percentage}
              aria-valuemin={0}
              aria-valuemax={100}
              onClick={handleClick}
              {...props}
            >
              <ProgressCircles
                strokeWidth={strokeWidth}
                radius={radius}
                circumference={circumference}
                percentage={percentage}
              />

              {showPercentage && (
                <div className="absolute inset-0 flex items-center justify-center">
                  <span className="text-lg font-semibold">{Math.round(percentage)}%</span>
                </div>
              )}
            </div>
          </TooltipTrigger>

          <TooltipContent>
            <span>{percentage}</span>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    );
  }
);

CircularProgress.displayName = 'CircularProgress';

import React from 'react';
import { DeckResponse, FlashcardResponse } from 'flashcards-api-schema';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';

interface FlashcardsTableProps {
  cards: FlashcardResponse[];
  decks: DeckResponse[];
}

export const FlashcardsTable: React.FC<FlashcardsTableProps> = ({ cards, decks }) => {
  const getDeckName = (deckId: string) => decks.find((d) => d.id === deckId)?.name || deckId;

  return (
    <div className="overflow-x-auto">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Deck</TableHead>

            <TableHead>Question</TableHead>

            <TableHead>Answer</TableHead>

            <TableHead>Mastery Score</TableHead>
          </TableRow>
        </TableHeader>

        <TableBody>
          {cards.map((card) => (
            <TableRow key={card.id}>
              <TableCell>{getDeckName(card.deckId)}</TableCell>

              <TableCell>{card.front}</TableCell>

              <TableCell>{card.back}</TableCell>

              <TableCell>{card.masteryScore ?? 'N/A'}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

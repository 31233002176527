import { useFetchDecks } from '@/query';
import { useStore } from './use-store';

interface DeckHeaderName {
  name: string;
  loading: boolean;
  error: string | null;
}

export const useDeckHeaderName = (): DeckHeaderName => {
  const { data: decks, isLoading, error } = useFetchDecks();
  const { selectedRegularDeckIds } = useStore();

  const getDeckPath = (deckId: string): string => {
    const deck = decks?.find((deck) => deck.id === deckId);
    if (!deck) return '';
    if (!deck.parentDeckId) return deck.name;
    return `${getDeckPath(deck.parentDeckId)} / ${deck.name}`;
  };

  if (isLoading) {
    return { name: '', loading: true, error: null };
  }

  if (error) {
    return { name: '', loading: false, error: error.message };
  }

  if (selectedRegularDeckIds.length > 1) {
    return {
      name: `${selectedRegularDeckIds.length} Decks Selected`,
      loading: false,
      error: null,
    };
  }

  if (selectedRegularDeckIds.length === 1) {
    return {
      name: getDeckPath(selectedRegularDeckIds[0]),
      loading: false,
      error: null,
    };
  }

  return { name: 'All Decks', loading: false, error: null };
};

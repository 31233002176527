const Definitions = () => (
  <defs>
    <linearGradient id="cardGradient" x1="0%" y1="0%" x2="100%" y2="100%">
      <stop offset="0%" stopColor="#4B0082" />

      <stop offset="100%" stopColor="#8A2BE2" />
    </linearGradient>

    <linearGradient id="starGradient" x1="0%" y1="0%" x2="100%" y2="100%">
      <stop offset="0%" stopColor="#FFD700" />

      <stop offset="100%" stopColor="#FFA500" />
    </linearGradient>

    <filter id="glow">
      <feGaussianBlur stdDeviation="3.5" result="coloredBlur" />

      <feMerge>
        <feMergeNode in="coloredBlur" />

        <feMergeNode in="SourceGraphic" />
      </feMerge>
    </filter>
  </defs>
);

export default function CardStackLogo() {
  return (
    <svg width="48" height="48" viewBox="-10 -20 220 220">
      <Definitions />

      <g transform="rotate(-15, 100, 100)">
        <rect
          x="30"
          y="70"
          width="140"
          height="100"
          rx="10"
          fill="url(#cardGradient)"
          opacity="0.4"
        />

        <rect
          x="40"
          y="60"
          width="140"
          height="100"
          rx="10"
          fill="url(#cardGradient)"
          opacity="0.7"
        />

        <rect x="50" y="50" width="140" height="100" rx="10" fill="url(#cardGradient)" />
      </g>

      <g filter="url(#glow)">
        <path d="M100 20C120 0 140 10 160 0C140 20 120 10 100 20Z" fill="url(#starGradient)" />

        <path
          d="M90 10C110 -10 130 0 150 -10C130 10 110 0 90 10Z"
          fill="url(#starGradient)"
          opacity="0.7"
        />
      </g>

      <g filter="url(#glow)">
        <path d="M150 35L152 40L157 42L152 44L150 49L148 44L143 42L148 40L150 35Z" fill="#FFFFFF" />

        <path
          d="M130 25L132 30L137 32L132 34L130 39L128 34L123 32L128 30L130 25Z"
          fill="#FFFFFF"
          opacity="0.7"
        />

        <path
          d="M170 55L171 58L174 59L171 60L170 63L169 60L166 59L169 58L170 55Z"
          fill="#FFFFFF"
          opacity="0.5"
        />
      </g>

      <circle cx="80" cy="40" r="2" fill="#FFFFFF" opacity="0.8" />

      <circle cx="160" cy="70" r="1.5" fill="#FFFFFF" opacity="0.6" />

      <circle cx="40" cy="90" r="1" fill="#FFFFFF" opacity="0.7" />

      <circle cx="180" cy="110" r="1.2" fill="#FFFFFF" opacity="0.5" />
    </svg>
  );
}

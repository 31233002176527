import React from 'react';
import { DeckResponse } from 'flashcards-api-schema';
import {
  Select,
  SelectTrigger,
  SelectContent,
  SelectItem,
  SelectValue,
} from '@/components/ui/select';

interface DeckFilterProps {
  deckFilter: string;
  setDeckFilter: (value: string) => void;
  decks: DeckResponse[];
}

export const DeckFilter: React.FC<DeckFilterProps> = ({ deckFilter, setDeckFilter, decks }) => (
  <div className="flex items-center gap-2">
    <label htmlFor="deckFilter">Filter by Deck:</label>

    <div>
      <Select value={deckFilter} onValueChange={setDeckFilter}>
        <SelectTrigger className="w-40">
          <SelectValue placeholder="All" />
        </SelectTrigger>

        <SelectContent>
          <SelectItem value="All">All</SelectItem>

          {decks.map((deck) => (
            <SelectItem key={deck.id} value={deck.id}>
              {deck.name}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  </div>
);

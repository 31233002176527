import { CenterContent } from './components/CenterContent';
import { FreshTopBar } from './components/TopBar';
import { flashcardRequestSchema } from 'flashcards-api-schema';
import { Toaster } from '@/components/ui/toaster';

export const FreshApp = () => {
  console.log(flashcardRequestSchema);
  return (
    <div className="flex h-screen flex-col">
      <FreshTopBar />

      <CenterContent />

      <Toaster />
    </div>
  );
};

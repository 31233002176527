import { useStore } from '@/hooks/use-store';
import { DecksView } from '../pages/decks/DecksView';
import BrowseView from '../pages/browse/Browse';
import { CreateView } from '../pages/create/CreateView';
import { SettingsView } from '../pages/settings/SettingsView';

export const CenterContent = () => {
  const { selectedTab } = useStore();

  const renderSwitch = (tab: 'decks' | 'browse' | 'create' | 'settings') => {
    switch (tab) {
      case 'decks':
        return <DecksView />;
      case 'browse':
        return <BrowseView />;
      case 'create':
        return <CreateView />;
      case 'settings':
        return <SettingsView />;
      default:
        return null;
    }
  };

  return <div className="h-full overflow-hidden p-8">{renderSwitch(selectedTab)}</div>;
};

import { create } from 'zustand';

type State = {
  selectedRegularDeckIds: string[];
  selectedCustomDeckId: string | null;
  isPinnedCardsOpen: boolean;
  selectedTab: 'decks' | 'browse' | 'create' | 'settings';
  decksTab: 'stats' | 'study';
};
type Action = {
  updateSelectedRegularDeckIds: (ids: State['selectedRegularDeckIds']) => void;
  updateSelectedCustomDeckId: (id: State['selectedCustomDeckId']) => void;
  updateIsPinnedCardsOpen: (open: State['isPinnedCardsOpen']) => void;
  updateSelectedTab: (tab: State['selectedTab']) => void;
  updateDecksTab: (tab: State['decksTab']) => void;
};

const initialState: State = {
  selectedRegularDeckIds: [],
  selectedCustomDeckId: null,
  isPinnedCardsOpen: false,
  selectedTab: 'decks',
  decksTab: 'stats',
};

export const useStore = create<State & Action>((set) => ({
  ...initialState,
  updateSelectedRegularDeckIds: (ids) =>
    set(() => ({
      selectedRegularDeckIds: ids,
      selectedCustomDeckId: null,
    })),
  updateSelectedCustomDeckId: (id) =>
    set(() => ({
      selectedCustomDeckId: id,
      selectedRegularDeckIds: [],
    })),
  updateIsPinnedCardsOpen: (open) => set(() => ({ isPinnedCardsOpen: open })),
  updateSelectedTab: (tab) => set(() => ({ selectedTab: tab })),
  updateDecksTab: (tab) => set(() => ({ decksTab: tab })),
}));

import { useMutation, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { api } from './api';
import { FlashcardRequest, FlashcardResponse } from 'flashcards-api-schema';

export const useFetchFlashcards = (): UseQueryResult<FlashcardResponse[], Error> => {
  return useQuery<FlashcardResponse[], Error>({
    queryKey: ['flashcards'],
    queryFn: async () => {
      const response = await api.get('/flashcards');
      return response.data;
    },
  });
};

export const useFetchFlashcard = (id: string) => {
  return useQuery<FlashcardResponse, Error>({
    queryKey: ['flashcards', id],
    queryFn: async () => {
      const response = await api.get(`/flashcards/${id}`);
      return response.data;
    },
    enabled: !!id,
  });
};

export const useCreateFlashcard = () => {
  const queryClient = useQueryClient();
  return useMutation<FlashcardResponse, Error, Partial<FlashcardResponse>>({
    mutationFn: async (newFlashcard: Partial<FlashcardResponse>) => {
      const response = await api.post('/flashcards', newFlashcard);
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['flashcards'] });
    },
  });
};

export const useUpdateFlashcard = () => {
  const queryClient = useQueryClient();
  return useMutation<FlashcardRequest, Error, { id: string; data: Partial<FlashcardRequest> }>({
    mutationFn: async ({ id, data }) => {
      const response = await api.patch(`/flashcards/${id}`, data);
      return response.data;
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: ['flashcards', variables.id] });
      queryClient.invalidateQueries({ queryKey: ['flashcards'] });
    },
  });
};

export const useDeleteFlashcard = () => {
  const queryClient = useQueryClient();
  return useMutation<void, Error, string>({
    mutationFn: async (id: string) => {
      await api.delete(`/flashcards/${id}`);
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: ['flashcards', variables] });
      queryClient.invalidateQueries({ queryKey: ['flashcards'] });
    },
  });
};

import { TrendingUp } from 'lucide-react';
import { CartesianGrid, XAxis, Bar, BarChart } from 'recharts';
import { ChartCard } from './ChartCard';
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from '@/components/ui/chart';
import React from 'react';
const chartConfig = {
  flashcards: {
    label: 'Flashcards',
    color: 'hsl(var(--chart-1))',
  },
} satisfies ChartConfig;

const chartData = [
  { month: 'January', flashcards: 120 },
  { month: 'February', flashcards: 150 },
  { month: 'March', flashcards: 200 },
  { month: 'April', flashcards: 180 },
  { month: 'May', flashcards: 220 },
  { month: 'June', flashcards: 250 },
];

export const MockChart: React.FC<object> = () => {
  const footer = (
    <>
      <div className="flex gap-2 font-medium leading-none">
        Trending up by 12.5% this month <TrendingUp className="h-4 w-4" />
      </div>

      <div className="leading-none text-muted-foreground">
        Showing total flashcards reviewed for the last 6 months
      </div>
    </>
  );

  return (
    <ChartCard title="Flashcards Usage" description="January - June 2024" footerContent={footer}>
      <ChartContainer config={chartConfig}>
        <BarChart accessibilityLayer data={chartData}>
          <CartesianGrid />

          <XAxis dataKey="month" tickMargin={10} tickFormatter={(value) => value.slice(0, 3)} />

          <ChartTooltip content={<ChartTooltipContent hideLabel />} />

          <Bar dataKey="flashcards" fill="var(--color-flashcards)" radius={8} />
        </BarChart>
      </ChartContainer>
    </ChartCard>
  );
};

import { useFetchFlashcards, useUpdateFlashcard } from '@/query';

export const usePinned = () => {
  const { data: flashcards, isLoading, error } = useFetchFlashcards();
  const updateFlashcard = useUpdateFlashcard();

  const pinned = flashcards?.filter((flashcard) => flashcard.isPinned);

  const clearCard = (id: string) => {
    const flashcard = flashcards?.find((card) => card.id === id);
    if (flashcard && flashcard.isPinned) {
      updateFlashcard.mutate({ id, data: { isPinned: false } });
    }
  };

  const clearAll = () => {
    pinned?.forEach((flashcard) => {
      updateFlashcard.mutate({ id: flashcard.id, data: { isPinned: false } });
    });
  };

  return { pinned, isLoading, error, clearCard, clearAll };
};

import React, { useState } from 'react';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Plus } from 'lucide-react';
import { useCreateDeck } from '@/query';
import { useDeckSelector } from './deck-selector/useDeckSelector';

const NewDeckDialog: React.FC = () => {
  const [newDeckName, setNewDeckName] = useState('');
  const [open, setOpen] = useState(false);
  const createDeck = useCreateDeck();
  const { deckId: parentDeckId, setDeckId, DeckSelectorComponent } = useDeckSelector();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (newDeckName.trim()) {
      createDeck.mutate({
        name: newDeckName.trim(),
        parentDeckId: parentDeckId,
      });
      setNewDeckName('');
      setDeckId(undefined);
      setOpen(false);
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="ghost" size="icon">
          <Plus />
        </Button>
      </DialogTrigger>

      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Create New Deck</DialogTitle>
        </DialogHeader>

        <form onSubmit={handleSubmit} className="grid gap-4 py-4">
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="name" className="text-right">
              Name
            </Label>

            <Input
              id="name"
              value={newDeckName}
              onChange={(e) => setNewDeckName(e.target.value)}
              className="col-span-3"
            />
          </div>

          <DeckSelectorComponent />

          <Button type="submit" className="ml-auto">
            Create Deck
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default NewDeckDialog;

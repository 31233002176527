// TODO: Add card to deck
// TODO: Remove card from deck
// TODO: Edit card
// TODO: Delete card
// TODO: Add deck
// TODO: Remove deck
// TODO: Edit deck
// TODO: Delete deck

import AddCardButton from '@/components/AddCardButton';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { useStore } from '@/hooks/use-store';
import React from 'react';
// TODO: Study deck
export const DeckActions: React.FC<object> = () => {
  const { decksTab, updateDecksTab } = useStore();
  return (
    <div className="flex justify-between">
      <Tabs defaultValue={decksTab} className="w-[400px]">
        <TabsList>
          <TabsTrigger value="stats" onClick={() => updateDecksTab('stats')}>
            Stats
          </TabsTrigger>

          <TabsTrigger value="study" onClick={() => updateDecksTab('study')}>
            Study
          </TabsTrigger>
        </TabsList>
      </Tabs>

      <AddCardButton />
    </div>
  );
};

import React, { useState } from 'react';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { useCreateFlashcard } from '@/query';
import { useDeckSelector } from './deck-selector/useDeckSelector';

const AddCardButton: React.FC = () => {
  const [front, setFront] = useState('');
  const [back, setBack] = useState('');
  const [open, setOpen] = useState(false);
  const createFlashcard = useCreateFlashcard();
  const { deckId, setDeckId, DeckSelectorComponent } = useDeckSelector();
  const cannotSubmit = !front.trim() || !back.trim() || !deckId;

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!cannotSubmit) {
      createFlashcard.mutate({ front, back, deckId });
      setFront('');
      setBack('');
      setDeckId(undefined);
      setOpen(false);
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button>Add Card</Button>
      </DialogTrigger>

      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Add Card</DialogTitle>
        </DialogHeader>

        <form onSubmit={handleSubmit} className="grid gap-4 py-4">
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="front" className="text-right">
              Front
            </Label>

            <Input
              id="front"
              value={front}
              onChange={(e) => setFront(e.target.value)}
              className="col-span-3"
            />
          </div>

          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="back" className="text-right">
              Back
            </Label>

            <Input
              id="back"
              value={back}
              onChange={(e) => setBack(e.target.value)}
              className="col-span-3"
            />
          </div>

          <DeckSelectorComponent />

          <Button type="submit" className="ml-auto" disabled={cannotSubmit}>
            Add Card
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AddCardButton;

'use client';

import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuList,
} from '@/components/ui/navigation-menu';
import { Button } from './ui/button';
import { ModeToggle } from './mode-toggle';
import { useStore } from '@/hooks/use-store';
import PinnedSheet from './PinnedSheet';
import CardStackLogo from './card-stack-logo';

export function NavigationMenuDemo() {
  const { selectedTab, updateSelectedTab } = useStore();

  const handleTabClick = (tab: 'decks' | 'browse' | 'create' | 'settings') => {
    updateSelectedTab(tab);
  };

  const getVariant = (tab: 'decks' | 'browse' | 'create' | 'settings') => {
    return selectedTab === tab ? 'default' : 'link';
  };

  return (
    <NavigationMenu>
      <NavigationMenuList>
        <NavigationMenuItem>
          <Button variant={getVariant('decks')} onClick={() => handleTabClick('decks')}>
            Decks
          </Button>
        </NavigationMenuItem>

        <NavigationMenuItem>
          <Button variant={getVariant('browse')} onClick={() => handleTabClick('browse')}>
            Browse
          </Button>
        </NavigationMenuItem>

        <NavigationMenuItem>
          <Button variant={getVariant('create')} onClick={() => handleTabClick('create')}>
            Create
          </Button>
        </NavigationMenuItem>

        <NavigationMenuItem>
          <Button variant={getVariant('settings')} onClick={() => handleTabClick('settings')}>
            Settings
          </Button>
        </NavigationMenuItem>
      </NavigationMenuList>

      <div className="flex items-center gap-2">
        <ModeToggle />

        <PinnedSheet />
      </div>
    </NavigationMenu>
  );
}

export const FreshTopBar = () => {
  return (
    <div className="flex items-center justify-between bg-gray-50 p-4 dark:bg-gray-900">
      <div className="flex items-center gap-2 text-gray-900 dark:text-gray-100">
        <CardStackLogo />

        <h1 className="font-electrolize text-2xl">lectro</h1>
      </div>

      <div>
        <NavigationMenuDemo />
      </div>
    </div>
  );
};

export default FreshTopBar;

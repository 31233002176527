import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api } from './api';
import { ReviewRequest, reviewRequestSchema } from 'flashcards-api-schema';

const REVIEW_BASE = 'flashcards/reviews';
const FLASHCARDS_QUERY_KEY = ['flashcards'];

interface MutationConfig {
  onSuccess?: () => void;
  onError?: (error: Error) => void;
}

// Mutation to create a review
export const useCreateReview = (config?: MutationConfig) => {
  const queryClient = useQueryClient();

  return useMutation<unknown, Error, ReviewRequest>({
    mutationFn: async (review: ReviewRequest) => {
      try {
        reviewRequestSchema.parse(review);
        const response = await api.post(REVIEW_BASE, review);
        return response.data;
      } catch (error) {
        if (error instanceof Error) {
          throw error;
        }
        throw new Error('Failed to create review');
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: FLASHCARDS_QUERY_KEY });
      config?.onSuccess?.();
    },
    onError: (error: Error) => {
      console.error('Review creation failed:', error);
      config?.onError?.(error);
    },
  });
};

// Mutation to update a review
export const useUpdateReview = (config?: MutationConfig) => {
  const queryClient = useQueryClient();

  return useMutation<unknown, Error, { reviewId: string; reviewRequest: ReviewRequest }>({
    mutationFn: async ({ reviewId, reviewRequest: data }) => {
      try {
        reviewRequestSchema.parse(data);
        const response = await api.put(`${REVIEW_BASE}/${reviewId}`, data);
        return response.data;
      } catch (error) {
        if (error instanceof Error) {
          throw error;
        }
        throw new Error('Failed to update review');
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: FLASHCARDS_QUERY_KEY });
      config?.onSuccess?.();
    },
    onError: (error: Error) => {
      console.error('Review update failed:', error);
      config?.onError?.(error);
    },
  });
};

// Mutation to delete a review
export const useDeleteReview = (config?: MutationConfig) => {
  const queryClient = useQueryClient();

  return useMutation<unknown, Error, string>({
    mutationFn: async (reviewId: string) => {
      try {
        const response = await api.delete(`${REVIEW_BASE}/${reviewId}`);
        return response.data;
      } catch (error) {
        if (error instanceof Error) {
          throw error;
        }
        throw new Error('Failed to delete review');
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: FLASHCARDS_QUERY_KEY });
      config?.onSuccess?.();
    },
    onError: (error: Error) => {
      console.error('Review deletion failed:', error);
      config?.onError?.(error);
    },
  });
};
